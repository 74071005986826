require('../../../../../vendor/oi/ux/assets/js/ux-modal-bs4');
import PhotoSwipeLightbox from '../../../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from '../../../../../node_modules/photoswipe/dist/photoswipe.esm.js';

require('../../../../../node_modules/photoswipe/dist/photoswipe.css')
var Sugar = require('sugar');

global.Sugar = Sugar;
Sugar.extend();

/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../../../../../vendor/oi/ux/assets/scss/spacing.scss');
require('../../../../../vendor/oi/ux/assets/scss/border.scss');
require('../../../../../vendor/oi/ux/assets/scss/square.scss');
require('../../../../../vendor/oi/ux/assets/scss/dl.scss');
require('../scss/frontend.scss');

//require('./contact/form');


function refreshCart() {
    $.get(Routing.generate('frontend_cart_heder'), function (html) {
        $('#shopping-cart-container').html(html);
    });
}

function addRefreshTrigger() {
    $('.btn[data-target="#cart-sidebar"]').click(function () {
        setTimeout(function () {
            refreshCart();
        }, 100);
    });
}


console.log('frontend.js')
document.addEventListener("DOMContentLoaded", function (event) {
    console.log('Ready!')
    setTimeout(function () {
        const lightbox = new PhotoSwipeLightbox({
            gallery: '.photoswipe',
            children: '.portfolio-item',
            pswpModule: () => PhotoSwipe
        });
        console.log(lightbox);
        lightbox.init();
    }, 100);
    $('.amazon-list .list-group-item').click(function () {
        $('.amazon-list .list-group-item').removeClass('active');
        $(this).addClass('active');
        var $card = $(this).closest('.card');
        $card.find('.amazon').removeClass('active');
        $card.find($(this).data('ref')).addClass('active');
    });
});

global.refreshCart = refreshCart;
global.addRefreshTrigger = addRefreshTrigger;
